
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";


@import "../../public/plugins/datepicker3.css";
@import "../../public/plugins/dataTables.bootstrap.css";

@import "../../public/plugins/daterangepicker.css";
@import "../assets/css/custom.css";

//Ionicons
@import "~ionicons/dist/scss/ionicons.scss";

//Font awesome
@import "~font-awesome/scss/font-awesome.scss";


@import "~admin-lte/dist/css/skins/_all-skins";


@import "../assets/css/adminlte-app.css";

//@import "~admin-lte/dist/css/AdminLTE";
//@import "~admin-lte/dist/css/adminlte-app.css";
//@import "../../../public/css/adminlte-app.css";

@import "~icheck/skins/square/blue";
@import "~icheck/skins/square/grey";
@import "../assets/css/chosen.min.css";
@import "../assets/css/tipso.min.css";
@import "../assets/css/jquery.simple-tree-picker.css";

// Toaster
//@import "~toastr/toastr";

@import "~bootstrap-duration-picker/src/bootstrap-duration-picker";

@import "dark-theme";

.close {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  color: white;
  opacity: unset; }

.content-header {
  padding-bottom: 15px;
  .breadcrumb {
    float: none;
    position: inherit;
    font-size: 14px;
    padding: 0px 10px;
    .active {
      font-weight: 400; } } }
.dropdown-submenu {
  position: relative;
  .caret-container {} }


.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%; }
.error-page {
  background-color: inherit;
  h2 {
    font-size: 420px !important;
    width: 100%; }
  .error-content {
    margin-left: 0px; } }
@media (max-width: 767px) {
  .error-page {
    h2 {
      font-size: 200px !important; } }
  .dropdown-submenu > .dropdown-menu {
    top: initial;
    left: initial; } }

.tip-info {
  background-color: #f5f8fa;
  border-left: 6px solid #3c8dbc;
  padding: 10px 15px;
  margin-bottom: 15px; }
.dataTable thead tr {
  background-color: #a0be86;
  color: #fff;
  text-transform: uppercase; }
.dataTable tbody tr:nth-child(2n+1) {
  background-color: #f2f2f2; }
.dataTable tbody tr {
  color: #000; }
.skin-blue .main-header .logo {
  background-color: #c4ca84 !important;
  height: 52px !important; }
.skin-blue .main-header .logo:hover {
  background-color: #c4ca84 !important; }
.skin-blue .main-header .navbar {
  background-color: #c4ca84; }
.dataTable tbody tr td {
  border-color: #eaeaea; }
.login-box-body,.register-box-body {
  background-color: #c4ca84;
  .login-box-msg {
    font-weight: bold; } }
.login-box-body .form-control,.register-box-body .form-control {
  background-color: #f2f2f2; }
.icheckbox_square-grey {
  border: 1px solid gray; }
.register-box-body .login-box-msg, .login-box-body .login-box-msg {
  color: #fff;
  font-size: 20px; }
.register-box-body a, .login-box-body a {
  color: #fff; }
.register-box-body .btn.btn-block.btn-flat, .login-box-body .btn.btn-block.btn-flat {
  color: #fff;
  background-color: #809468; }
.skin-blue .wrapper, .skin-blue .main-sidebar, .skin-blue .left-side {
  background-color: #8fa272; }
.main-sidebar .sidebar-menu a {
  color: #fff; }
.skin-blue .sidebar-menu > li:hover > a {
  background-color: #ded68e;
  border-left-color: #8fa272; }
.skin-blue .sidebar-menu > li:hover > a > span {
  color: black; }
.skin-blue .sidebar-menu > li.active > a {
  background-color: #c4ca84;
  border-left-color: #8fa272; }
.content-header .breadcrumb {
  background-color: #ecf0f5; }
.main-header {
  border-bottom: 1px solid gray; }
.auth-logo {
  padding: 0px;
  width: 100%;
  clear: both;
  display: block;
  height: fit-content; }
.auth-logo img {
  width: 100%; }

@media screen and (max-width: 768px) {
  .pinned {
    width: 35%; }
  div.table-wrapper div.scrollable {
    margin-left: 35%; } }
@media screen and (min-width: 769px) and (max-width: 1368px) {
  .pinned {
    width: 25%; }
  div.table-wrapper div.scrollable {
    margin-left: 25%; } }
@media screen and (max-width: 1368px) {
  table.responsive {
    margin-bottom: 0; }
  .pinned {
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    width: 25%;
    overflow: hidden;
    overflow-x: scroll; }
  .pinned table {
    border-right: none;
    border-left: none;
    width: 100%; }
  .pinned table th, .pinned  table td {
    white-space: nowrap; }
  .pinned table td:last-child {
    border-bottom: 0; }
  div.table-wrapper {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    border-right: 1px solid #ccc; }
  div.table-wrapper div.scrollable {
    margin-left: 25%;
    overflow: scroll;
    overflow-y: hidden; }
  table.responsive td {
    position: relative;
    white-space: nowrap;
    overflow: hidden; }
  table.responsive th {
    position: relative;
    white-space: nowrap;
    overflow: hidden; }
  .tooltip.mobile-toltip {
    display: inline-block !important; } }
.tooltip.tooltip-top {
  position: relative;
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
  padding: 5px 0; }
.tooltip.tooltip-top .tooltip-inner {
  max-width: 320px;
  background-color: #f39c12; }
.mobile-toltip {
  display: none !important; }
.tooltip.tooltip-top .tooltip-inner::before {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #f39c12; }
@media screen and (min-width: 1025px) and (max-width: 1270px) {
  .users-table td:nth-child(5), .users-table th:nth-child(5), .users-table td:nth-child(6), .users-table th:nth-child(6) {
    display: none; } }
@media screen and (min-width: 1024px) {
  .register-box {
    width: 560px; } }
.orange-tooltip {
  background-color: #f39c12;
  padding: 4px 10px;
  border-radius: 5px;
  display: block;
  min-height: 36px;
  font-size: 14px;
  line-height: 14px;
  color: #fff; }
.btn-main-register {
  background-color: #3c8dbc !important; }
.g-recaptcha {
  transform: scale(0.81);
  -webkit-transform: scale(0.81);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0; }
.treeview.active .treeview-menu.menu-open li > a {
  background-color: #d69e2d;
  color: #fff; }
.skin-blue .sidebar-menu > li > .treeview-menu {
  background-color: inherit !important; }
.sidebar-menu .active a {
  background-color: #d69e2d !important; }
.nav.navbar-nav li > .btn-group {
  margin-left: 10px; }
.nav.navbar-nav li > .btn-group > .btn-warning {
  background-color: #3c8dbc;
  border-color: #3c8dbc; }
.navbar-nav > li > a {
  line-height: 24px; }
.skin-blue .main-header .navbar .nav > .active > a {
  background: #d69e2d !important; }
.orange-tooltip.linear-tooltip {
  height: 30px;
  line-height: 22px;
  margin-right: 10px; }
.login-box-body .btn.btn-block.btn-flat {
  background-color: #3c8dbc;
  border-color: #3c8dbc; }
.small-box .icon {
  font-size: 70px !important; }
.margin-button-bottom {
  margin-bottom: 10px !important; }
.margin-button-top {
  margin-top: 10px !important; }
.margin-button-right {
  margin-right: 10px !important; }
.margin-button-left {
  margin-left: 10px !important; }
//.small-box.bg-aqua
//background-color: #00c0ef !important
//.small-box.bg-aqua a
//background-color: #00c0ef !important
//.small-box.bg-green
//background-color: #00a65a !important
//.small-box.bg-green a
//background-color: #00a65a !important
//.small-box.bg-yellow
//background-color: #f39c12  !important
//.small-box.bg-yellow a
//background-color: #f39c12 !important*/
.treeview.active > a {
 border-bottom : 1px solid #fff; }
.treeview li.active > a {
 background-color: #f47a21 !important; }
.form label {
  font-weight: bold !important; }
select[name="group_1"] {
  width: 100%;
  height: 36px;
  margin-bottom: 15px; }
.register-logo a.background-dark, .login-logo  a.background-dark {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px 30px; }
.organization-buttons {
  width: 100px;
  float: right; }

.organization-buttons a {
  width: 130px;
  text-align: left; }
.organization-buttons {
  padding-right: 0px; }
.help-button {
  width: 130px; }
.treeview-menu.menu-open > li:not(:last-child) {
  border-bottom: 1px solid #fff !important; }
.white-color {
  color: #fff !important; }
.login-page, .register-page {
  background-attachment: fixed; }
.version-container {
  font-size: 14px;
  width: 100px;
  font-weight: 300;
  font-style: italic; }
.custom-login-box {
  width: auto !important;
  max-width: 768px; }
.orange-background {
  color: #dd4b39 !important; }
.required-glyph {
  padding-right: 40px;
  color: #dd4b39 !important; }
select.chosen-select {
  opacity: 0; }
.has-feedback textarea {
  height: 72px; }
.tooltip-input {
  width: 90% !important;
  display: inline-block; }
.tooltip-question {
  float: right;
  font-size: 24px;
  color: #f57a20; }
.register-box-body {
  margin-bottom: 200px; }
.tooltip-question-checkbox {
  float: none; }
.chosen-container {
  width: 100% !important; }
.form .tooltip-input {
  width: 95% !important; }
form.register-form .tooltip-input {
  width: 95% !important; }
.tooltip-asterisk {
  right: 25px; }
.checkbox-text {
  width: 84%;
  margin-bottom: 10px; }
.checkbox-tooltip {
  right: 0px;
  position: absolute;
  margin-right: 0px !important; }
form.register-form .col-md-12 .icheckbox_square-grey {
  float: left;
  margin-right: 10px; }
@media screen and (max-width: 768px) {
  .form .tooltip-input,form.register-form .tooltip-input {
    width: 90% !important; }
  .checkbox-text {
    width: 73% !important; }
  .box-header > .box-tools {
    position: inherit; } }
.position-relative {
  position: relative; }
.table-drag-helper {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  cursor: pointer; }
.table-drag-helper i.fa-arrows-h {
  font-size: 200px;
  display: block;
  text-align: center; }
@media screen and (max-width: 1024px) {
  .position-relative .table-drag-helper {
    z-index: 2; } }
.fields-multiplie-block-container.drag-container li {
  cursor: all-scroll;
  list-style: none; }
.placeholder-line {
  outline: 1px dashed #f39c12;
  margin-bottom: 20px; }
ol.fields-multiplie-block-container {
  padding-left: 0px; }
.groups-form .panel-body {
  padding: 12px 15px 0px; }
.groups-form .panel-heading {
  padding: 3px 15px !important; }
.disabled-color {
  background-color: #dd4b3924 !important; }
@media screen and (min-width: 1368px) and (max-width: 1720px) {
  .users-table th:nth-child(1), .users-table th:nth-child(6), .users-table td:nth-child(1), .users-table td:nth-child(5) {
    display: none; } }
@media screen and (max-width: 1600px) {
  .navbar.navbar-static-top > a {
    display: none !important; } }
.treeview li > a {
  color: #fff !important; }
.sidebar-menu a {
  background-color: #8fa272; }
.required-glyph-select {
  padding-right: 60px !important; }
.tooltip {
  width: 250px !important; }
